@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background: linear-gradient(#F8F9F4 0%, #FDFEFE 100%);
    color: #1f1f1f;
    min-height: 100%;
}

html.dark {
    background: #111;
    color: #bebebe;
}

body {
    font-family: 'Proxima Nova', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont;
}